import config from '../../config';
import {
  sendRequest,
  emptyResponseValidator,
  createResponseValidator,
  createAuthHeader,
} from './send-request';
import {
  ChangePasswordRequest,
  ForgotPasswordRequest,
  DetectionAttachmentUploadRequest,
  DetectionAttachmentUploadResponseSchema,
  GetDetectionsRequest,
  GetDetectionsResponse,
  GetDetectionsResponseSchema,
  GetDetectionTotalCountsResponseSchema,
  GetSingleDetectionResponse,
  GetSingleDetectionResponseSchema,
  GetUsersRequest,
  GetUsersResponse,
  GetUsersResponseSchema,
  LoginRequest,
  LoginResponse,
  LoginResponseSchema,
  LogoutRequest,
  PatchUserRequest,
  RegisterRequest,
  RegisterResponse,
  RegisterResponseSchema,
  ResetPasswordRequest,
  SetUserRoleRequest,
  UpdateDetectionRequest,
  UpdateDetectionResponse,
  UpdateDetectionResponseSchema,
  UserRole,
  DetectionAttachmentUploadRequestSchema,
  GetDetectionTotalCountsRequest,
} from '../../../../common/src/api/v2';

/// REST API endpoints
export const endpoints = {
  CHANGE_PASSWORD: '/v2/change-password',
  FORGOT_PASSWORD: '/v2/forgot-password',
  LOGIN: '/v2/login',
  LOGOUT: '/v2/logout',
  REGISTER: '/v2/register',
  RESET_PASSWORD: '/v2/reset-password',
  DETECTIONS: '/v2/detections',
  DETECTIONS_TOTAL_COUNTS: '/v2/detection-total-counts',
  USERS: '/v2/users',
};
// Append the base URI to the defined endpoint paths
for (const [key, path] of Object.entries(endpoints))
  endpoints[key] = new URL(path, config.apiEndpoint).href;
// Helper for endpoints that require ID params
function appendToEndpoint(endpoint: string, ...tails: string[]) {
  const endpointWithId = new URL(endpoint);
  for (const tail of tails) {
    // append ID as path param
    if (endpointWithId.pathname.endsWith('/')) {
      endpointWithId.pathname += tail;
    } else {
      endpointWithId.pathname += `/${tail}`;
    }
  }
  return endpointWithId.href;
}

/// API helper functions
export function changePassword(
  email: string,
  oldPassword: string,
  newPassword: string
): Promise<{}> {
  const data: ChangePasswordRequest = {
    email,
    oldPassword,
    newPassword,
  };

  return sendRequest('post', endpoints.CHANGE_PASSWORD, data, emptyResponseValidator);
}

export function forgotPassword(email: string): Promise<{}> {
  const data: ForgotPasswordRequest = {
    email,
  };

  return sendRequest('post', endpoints.FORGOT_PASSWORD, data, emptyResponseValidator);
}

export function login(email: string, password: string): Promise<LoginResponse> {
  const data: LoginRequest = {
    email,
    password,
  };

  return sendRequest('post', endpoints.LOGIN, data, createResponseValidator(LoginResponseSchema));
}

export function logout(token: string): Promise<{}> {
  const data: LogoutRequest = {
    token,
  };

  return sendRequest('post', endpoints.LOGOUT, data, emptyResponseValidator);
}

export function register(
  fullName: string,
  email: string,
  role: UserRole,
  token: string
): Promise<RegisterResponse> {
  const data: RegisterRequest = {
    email,
    fullName,
    role,
  };

  return sendRequest(
    'post',
    endpoints.REGISTER,
    data,
    createResponseValidator(RegisterResponseSchema),
    { headers: createAuthHeader(token) }
  );
}

export function resetPassword(token: string, password: string): Promise<{}> {
  const data: ResetPasswordRequest = {
    token,
    password,
  };

  return sendRequest('post', endpoints.RESET_PASSWORD, data, emptyResponseValidator);
}

/**
 * Get a list of detections.
 *
 * @param page corresponds to the offset in the query (0-indexed)
 * @param pageSize corresponds to which page of the query
 */
export function getDetections(
  getDetectionsRequest: GetDetectionsRequest,
  token: string
): Promise<GetDetectionsResponse> {
  return sendRequest(
    'get',
    endpoints.DETECTIONS,
    getDetectionsRequest,
    createResponseValidator(GetDetectionsResponseSchema),
    { headers: createAuthHeader(token) }
  );
}

export function getDetectionById(id: string, token: string) {
  return sendRequest(
    'get',
    appendToEndpoint(endpoints.DETECTIONS, id),
    null,
    createResponseValidator(GetSingleDetectionResponseSchema),
    {
      headers: createAuthHeader(token),
    }
  ) as Promise<GetSingleDetectionResponse>;
}

export function updateDetection(
  id: string,
  updateDetectionRequest: UpdateDetectionRequest,
  token: string
) {
  return sendRequest(
    'patch',
    appendToEndpoint(endpoints.DETECTIONS, id),
    updateDetectionRequest,
    createResponseValidator(UpdateDetectionResponseSchema),
    {
      headers: createAuthHeader(token),
    }
  ) as Promise<UpdateDetectionResponse>;
}

/**
 * Get a list of users.
 * This endpoint is only accessible to admins.
 *
 * @param page corresponds to the offset in the query (0-indexed)
 * @param pageSize corresponds to which page of the query
 */
export function getUsers(
  getusersRequest: GetUsersRequest,
  token: string
): Promise<GetUsersResponse> {
  return sendRequest(
    'get',
    endpoints.USERS,
    getusersRequest,
    createResponseValidator(GetUsersResponseSchema),
    { headers: createAuthHeader(token) }
  );
}

/**
 * Update a user's details.
 * This endpoint is only accessible to admins.
 *
 * @param id user's id
 * @param patchUserRequest user details to update
 * @param token
 * @returns nothing
 */
export function updateUser(id: string, patchUserRequest: PatchUserRequest, token: string) {
  return sendRequest(
    'patch',
    appendToEndpoint(endpoints.USERS, id),
    patchUserRequest,
    emptyResponseValidator,
    {
      headers: createAuthHeader(token),
    }
  );
}

/**
 * Set a user's role.
 * This endpoint is only accessible to admins.
 *
 * @param id user's id
 * @param setRoleRequest object containing user role to set
 * @param token
 * @returns nothing
 */
export function setUserRole(id: string, setRoleRequest: SetUserRoleRequest, token: string) {
  return sendRequest(
    'patch',
    appendToEndpoint(endpoints.USERS, id, 'role'),
    setRoleRequest,
    emptyResponseValidator,
    {
      headers: createAuthHeader(token),
    }
  );
}

/**
 * Send a notification to all users about a particular detection.
 * This endpoint is only accessible to admins.
 *
 * @param id detection id
 * @param token
 * @returns nothing
 */
export function notifyNewDetection(id: string, token: string) {
  return sendRequest(
    'post',
    appendToEndpoint(endpoints.DETECTIONS, id, 'notify'),
    null,
    emptyResponseValidator,
    {
      headers: createAuthHeader(token),
    }
  );
}

/**
 * Delete a user.
 *
 * @param id user id
 * @param token
 * @returns nothing
 */
export function deleteUser(id: string, token: string) {
  return sendRequest(
    'delete',
    appendToEndpoint(endpoints.USERS, id),
    null,
    emptyResponseValidator,
    {
      headers: createAuthHeader(token),
    }
  );
}

/**
 * Get detection total counts metadata.
 *
 * @param id user id
 * @param token
 * @returns nothing
 */
export function getDetectionTotalCounts(query: GetDetectionTotalCountsRequest, token: string) {
  return sendRequest(
    'get',
    endpoints.DETECTIONS_TOTAL_COUNTS,
    query,
    createResponseValidator(GetDetectionTotalCountsResponseSchema),
    {
      headers: createAuthHeader(token),
    }
  );
}

/**
 * Request a presigned upload URL for directly uploading a detection attachment to S3.
 *
 * @param detectionId
 * @param filename
 * @param token
 * @returns
 */
export function getDetectionAttachmentPresignedUpload(
  detectionId: string,
  filename: string,
  contentType: string,
  token: string
) {
  const presignedUploadReq: DetectionAttachmentUploadRequest = {
    filename,
    contentType,
  };
  return sendRequest(
    'post',
    appendToEndpoint(endpoints.DETECTIONS, detectionId, 'presigned-upload'),
    presignedUploadReq,
    createResponseValidator(DetectionAttachmentUploadResponseSchema),
    {
      headers: createAuthHeader(token),
    }
  );
}

/**
 * Updates a detection with a new attachment.
 *
 * @param detectionId
 * @param filename Alphanumeric + dashes and underscores
 * @param contentType MIME type
 * @param token
 * @returns
 */
export function updateDetectionAttachment(
  detectionId: string,
  filename: string,
  contentType: string,
  token: string
) {
  const attachmentReq = DetectionAttachmentUploadRequestSchema.parse({
    filename,
    contentType,
  });
  return sendRequest(
    'post',
    appendToEndpoint(endpoints.DETECTIONS, detectionId, 'attach'),
    attachmentReq,
    emptyResponseValidator,
    {
      headers: createAuthHeader(token),
    }
  );
}

/**
 * Deletes a detection attachment.
 *
 * @param detectionId
 * @param filename
 * @param token
 * @returns
 */
export function deleteDetectionAttachment(detectionId: string, filename: string, token: string) {
  return sendRequest(
    'delete',
    appendToEndpoint(endpoints.DETECTIONS, detectionId, 'attach', filename),
    null,
    emptyResponseValidator,
    {
      headers: createAuthHeader(token),
    }
  );
}
