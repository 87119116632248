import { Card, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import './InfoCard.css';

const { Title } = Typography;

interface InfoCardProps {
  children?: React.ReactNode,
  title: string,
  type: string,
}

function InfoCard({ type, title, children }: InfoCardProps):JSX.Element {
  return (
    <Card className="info-card-bg">
      <div className={`info-card-title-div ${type}`}>
        <Title className="info-card-title" level={3}>{title}</Title>
      </div>
      {children}
    </Card>
  );
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'primary', 'danger','warning','info','success']).isRequired,
};

export default InfoCard;