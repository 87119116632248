import { Link, useHistory } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';
import InfoCard from '../../../../components/cards/InfoCard/InfoCard';
import './Login.css';
import { LoginRequestSchema } from '../../../../../../common/src/api/v2';
import useUserStore from '../../../../data/session/user';

const LoginForm: React.FC = () => {
  const history = useHistory();
  const { Text } = Typography;

  const [login] = useUserStore((store) => [store.login]);

  const onFinish = async (values: Record<string, unknown>) => {
    const loginRequest = LoginRequestSchema.parse({
      email: values.email,
      password: values.password,
    });

    try {
      await login(loginRequest.email, loginRequest.password);
      history.push({
        pathname: '/admin/detections',
      });
    } catch (e) {
      message.error('Login failed');
    }
  };

  return (
    <InfoCard title="Login to Detect-It" type="default">
      <Form
        className="login-form"
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email address' }]}
        >
          <Input size="large" placeholder="Email" className="large-input" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password' }]}
        >
          <Input.Password size="large" placeholder="Password" className="large-input" />
        </Form.Item>

        <Form.Item>
          <Link to="/forgot-password">
            <Text className="link-text">Set new password</Text>
          </Link>
        </Form.Item>

        <Form.Item>
          <Button className="login-form-btn" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </InfoCard>
  );
};

export default LoginForm;
