import { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import { Card, Button, Row, Col, Typography, Radio, Modal, Input, Form, message } from 'antd';
import {
  CloudDownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import Map from '../Map/Map';
import Waveform from '../Waveform/Waveform';
import PropTypes from 'prop-types';
import * as utilities from '../../../../../data/utilities/detect-it-utilities';
import {
  detectionClassifications,
  GetSingleDetectionResponse,
} from '../../../../../../../common/src/api/v2';
import useUserStore from '../../../../../data/session/user';
import { updateDetection } from '../../../../../data/api/queries';

import './DetectionDetails.css';
import Upload from '../Upload/Upload';
import config from '../../../../../config';

interface DetectionWithKey extends GetSingleDetectionResponse {
  key: string;
}
export interface DetectionDetailsProps {
  record: DetectionWithKey;
  updateData: () => Promise<void>;
}

const DetectionDetails: React.FC<DetectionDetailsProps> = ({ record, updateData }) => {
  const [token] = useUserStore((store) => [store.token]);
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const { confirm } = Modal;

  const [detectionType, setDetectionType] = useState(
    utilities.formatEventType(record.classification)
  );

  const lat = Number(record.latitude);
  const lng = Number(record.longitude);

  const onChange = (e) => {
    const prevDetectionType = detectionType;
    setDetectionType(e.target.value);
    if (utilities.formatEventType(record.classification) !== e.target.value) {
      showReclassifyConfirm(record, e.target.value, prevDetectionType, updateData);
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function showArchiveConfirm(detection: DetectionWithKey) {
    if (!token) {
      return;
    }
    const titleAction = detection.isArchived ? 'unarchive' : 'archive';
    const contentAction = detection.isArchived ? 'archive' : 'unarchive';
    confirm({
      title:
        'Are you sure you want to ' +
        titleAction +
        ' detection ' +
        detection.id.split('-')[0] +
        '?',
      icon: <ExclamationCircleOutlined />,
      content: 'You may ' + contentAction + ' it later',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      async onOk() {
        await updateDetection(
          detection.id,
          {
            isArchived: !detection.isArchived,
          },
          token
        );
        updateData();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function showReclassifyConfirm(
    record: DetectionWithKey,
    type: string,
    prevDetectionType: string,
    updateData: () => void
  ) {
    if (!token) {
      return;
    }
    const unformattedType = utilities.unformatEventType(type);
    if (
      !(unformattedType === 'unclassified') &&
      !(unformattedType === 'starling-event') &&
      !(unformattedType === 'under-investigation')
    ) {
      return;
    }
    confirm({
      title: 'Are you sure you want to reclassify the event as ' + type + '?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      async onOk() {
        await updateDetection(
          record.id,
          {
            classification: unformattedType,
          },
          token
        );
        updateData();
      },
      onCancel() {
        setDetectionType(prevDetectionType);
        console.log('Cancel');
      },
    });
  }

  const url = config.s3WavBucketUrl + record.id + '/' + record.originalFilename;

  const saveFile = () => {
    FileSaver.saveAs(url, record.originalFilename);
  };

  const onFinish = async (values: Record<string, string>) => {
    if (!token) {
      return;
    }
    if (!values.notes) {
      return;
    }
    try {
      await updateDetection(
        record.id,
        {
          notes: values.notes,
        },
        token
      );
      updateData();
      form.resetFields();
      handleCancel();
    } catch (err) {
      console.log(err);
      message.error('Failed to update notes');
      updateData();
      form.resetFields();
      handleCancel();
    }
  };

  const defaultValues = {
    role: record.notes,
  };
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  return (
    <>
      <Row key="row-2" className="in-cell-row">
        <Card className="card-full-width">
          <Row>
            <Col span={8}>
              <Title level={5}>Classify Event</Title>
            </Col>
            <Col span={16}>
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    return showArchiveConfirm(record);
                  }}
                  icon={<ContainerOutlined />}
                >
                  {record.isArchived ? 'Unarchive Event' : 'Archive Event'}
                </Button>
                <br />
                <br />
              </Row>
            </Col>
          </Row>
          <div>
            <div className="break" />
            <Radio.Group
              options={utilities.formatEventsType(detectionClassifications as unknown as string[])}
              onChange={onChange}
              value={detectionType}
            />
            <br />
          </div>
          <br />
          <Waveform url={url} id={record.id} />
        </Card>
      </Row>
      <Row key="row-1" className="in-cell-row">
        <Card className="card-full-width">
          <Title level={5} className="in-cell-card-title">
            Details
          </Title>
          <Text strong>Full ID: </Text> <Text>{record.id}</Text>
          <br />
          <Text strong>Device ID: </Text> <Text>{record.deviceId || 'N/A'}</Text>
          <br />
          <Text strong>Timestamp: </Text> <Text>{record.createdAt.toString()}</Text>
          <br />
          <Text strong>File name: </Text> <Text>{record.originalFilename}</Text>
          <br />
          <br />
          <Button onClick={saveFile} icon={<CloudDownloadOutlined />} type="primary">
            Download {record.originalFilename}
          </Button>
        </Card>
      </Row>
      <Row key="row-3" className="in-cell-row">
        <Card className="card-full-width">
          <Col span={8}>
            <Title level={5}>Attachments</Title>
            <Upload detection={record} updateData={updateData}></Upload>
          </Col>
        </Card>
      </Row>
      <Row key="row-4" className="in-cell-row">
        <Card className="card-full-width">
          <Row>
            <Col span={8}>
              <Title level={5}>Metadata</Title>
            </Col>
            <Col span={16}>
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    return showModal();
                  }}
                  icon={<EditOutlined />}
                >
                  Update notes
                </Button>
                <br />
                <br />
              </Row>
            </Col>
          </Row>
          <Text strong>Latitude: </Text> <Text>{record.latitude}</Text>
          <br />
          <Text strong>Longitude: </Text> <Text>{record.longitude}</Text>
          <br />
          <Text strong>Notes: </Text> <Text>{record.notes}</Text>
          <br />
          <br />
          <Map lat={lat} lng={lng} />
        </Card>
      </Row>
      <Modal
        style={{ top: 50 }}
        width={400}
        title="Update notes"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          key={record.id}
          className="login-form"
          layout="vertical"
          name={record.id + '-update-role-form'}
          initialValues={defaultValues}
          onFinish={onFinish}
        >
          <Form.Item
            key="notes-input"
            name="notes"
            label={'Update notes for detection ' + record.id}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button
              key="notes-form-btn"
              className="notes-form-btn"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

DetectionDetails.propTypes = {
  record: PropTypes.any.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default DetectionDetails;
