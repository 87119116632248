import create from 'zustand';
import * as zod from 'zod';
import { login, logout } from '../api/queries';
import { UserRole, UserRoleSchema } from '../../../../common/src/api/v2';

/** Key used for persisting UserState to LocalStorage */
export const USER_STORE_KEY = 'UserStore';

export interface UserStore {
  id: string | null;
  email: string | null;
  role: UserRole | null;
  token: string | null;
  status: 'authenticated' | 'not-authenticated' | 'uninitialised';
  expiresAt: Date | null;

  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

/**
 * Validator for object interface to be serde'd to/from LocalStorage.
 */
export const UserStoreLocalStorageSchema = zod.object({
  id: zod.string().uuid(),
  email: zod.string(),
  role: UserRoleSchema,
  token: zod.string(),
  expiresAt: zod.string(),
});

/**
 * Object interface for serialisable state (see above).
 */
export type UserStoreLocalStorage = zod.infer<typeof UserStoreLocalStorageSchema>;

const useUserStore = createUserStore();

export default useUserStore;

export function createUserStore() {
  return create<UserStore>((set, get, _api) => {
    /**
     * Attempt to hydrate store from LocalStorage
     * TODO(kevincharm): Reuse.
     */
    let userStoreLocalStorage: UserStoreLocalStorage | null = null;
    try {
      userStoreLocalStorage = UserStoreLocalStorageSchema.parse(
        JSON.parse(localStorage.getItem(USER_STORE_KEY)!)
      );
    } catch (err) {
      console.warn(`Could not retrieve ${USER_STORE_KEY} from LocalStorage.`);
    }

    const defaultState = !!userStoreLocalStorage
      ? {
          id: userStoreLocalStorage.id,
          email: userStoreLocalStorage.email,
          role: userStoreLocalStorage.role,
          token: userStoreLocalStorage.token,
          status: 'authenticated' as const,
          expiresAt: new Date(userStoreLocalStorage.expiresAt),
        }
      : {
          id: null,
          email: null,
          role: null,
          token: null,
          status: 'not-authenticated' as const,
          expiresAt: null,
        };

    return {
      ...defaultState,

      login: async (email: string, password: string) => {
        const payload = await login(email, password);

        const { id, token, role, expiresAt } = payload;
        set({
          id,
          email,
          role,
          token,
          expiresAt: new Date(expiresAt),
          status: 'authenticated',
        });

        const userSessionLocal: UserStoreLocalStorage = {
          id,
          email,
          role,
          token,
          expiresAt: expiresAt.toISOString(),
        };
        localStorage.setItem(USER_STORE_KEY, JSON.stringify(userSessionLocal));
      },

      logout: async () => {
        const token = get().token;

        // Only make the API call if we actually have a token stored
        if (token) {
          await logout(token);
        }

        localStorage.removeItem(USER_STORE_KEY);
        set({
          id: null,
          email: null,
          role: null,
          token: null,
          expiresAt: null,
          status: 'not-authenticated',
        });
        return true;
      },
    };
  });
}
