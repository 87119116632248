import React, { useEffect, useRef, useState } from 'react';
import { Popover, Typography } from 'antd';
import config from '../../../../../config';

interface ImgProps {
  record: Record<string, string>;
}

const Preview: React.FC<ImgProps> = ({ record }) => {
  const imgRef = useRef<any>();
  const [noPreview, setNoPreview] = useState<JSX.Element>();
  const [shouldShowNoPreview, setShouldShowNoPreview] = useState(false);
  const { Text } = Typography;
  const url = config.s3WavBucketUrl + record.id + '/' + record.previewImageUrl;

  useEffect(() => {
    let newHeight = imgRef.current && imgRef.current.height ? imgRef.current.height : 0;
    setTimeout(() => {
      setNoPreview(newHeight === 0 ? <Text>No audio preview</Text> : undefined);
    }, 10);
  }, [imgRef, Text]);
  const largeImage = <img className="spect-preview-large" alt="" src={url} />;
  return (
    <>
      <Popover placement="left" content={largeImage}>
        <img
          ref={(el) => (imgRef.current = el)}
          className="spect-preview"
          alt=""
          src={url}
          onError={() => setShouldShowNoPreview(true)}
        />
      </Popover>
      {shouldShowNoPreview && noPreview}
    </>
  );
};

export default Preview;
