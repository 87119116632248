const site = {
  name: 'Detect It',
  provider: 'DKB Solutions',
}

const yearNum = new Date().getFullYear();
const year = yearNum.toString();

const footerString = site.name + '  © ' + year + ' Created by ' + site.provider;

export default footerString;