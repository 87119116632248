import { useState } from 'react';
import { Link, Switch, Route, useHistory } from 'react-router-dom';
import { UserOutlined, CheckSquareOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import AdminLogo from '../AdminLogo/AdminLogo';
import routes from '../../../routes';
import footerText from '../../../siteinfo/footertext';
import useUserStore from '../../../../data/session/user';

import 'antd/dist/antd.css';
import './AdminLayout.css';

const AdminLayout: React.FC = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const [email, logout] = useUserStore((store) => [store.email, store.logout]);
  const history = useHistory();
  if (!email) {
    history.replace({
      pathname: '/login',
    });
  }

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === '/admin') {
          return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
        }
      })}
    </Switch>
  );

  const [collapsed, setCollapsed] = useState(false);

  const [current, setCurrent] = useState('1');
  const handleClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className="admin-sider"
        collapsible
        collapsed={collapsed}
        onCollapse={() => {
          return setCollapsed(!collapsed);
        }}
      >
        <AdminLogo />
        <Menu
          theme="dark"
          onClick={handleClick}
          defaultSelectedKeys={['1']}
          selectedKeys={[current]}
          mode="inline"
        >
          <Menu.Item key="1">
            <Link to="/admin/detections">
              <CheckSquareOutlined />
              <span>Detections</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/admin/usermanagement">
              <UserOutlined />
              <span>User Management</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/login" onClick={logout}>
              <LogoutOutlined />
              <span>Logout</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="admin-header" style={{ background: '#fff', padding: 0 }} />
        <Content className="admin-content">{switchRoutes}</Content>
        <Footer className="admin-footer">{footerText}</Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
