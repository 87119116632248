import InfoCard from '../../../../components/cards/InfoCard/InfoCard';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Input, Button, Typography, message } from 'antd';
import { resetPassword } from '../../../../data/api/queries';
import './ResetPassword.css';

const ResetPassword: React.FC = () => {
  const { Text } = Typography;
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();
  const params = location.search;
  let token: string;
  if (!params.includes('token')) {
    message.error('Token is missing');
  } else {
    token = params.split('=').slice(-1)[0];
  }

  const onFinish = (values: Record<string, string>) => {
    if (!token) {
      message.error('Token unavailable');
      return;
    }
    async function handleResetPassword() {
      try {
        const data = await resetPassword(token, values.password);
        return data;
      } catch (e) {
        message.error('Cannot reset password');
      }
    }
    if (values.confirmation !== values.password) {
      message.error('Password entries do not match, please submit again');
      form.resetFields();
    } else if (values.password.length < 8) {
      message.error('Password length needs to be at least 8 symbols');
      form.resetFields();
    } else {
      handleResetPassword()
        .then((res) => {
          if (!(res === undefined)) {
            message.success(
              'The new password has been set successfully. Please use your new credentials to log in.'
            );
            history.push({
              pathname: '/login',
            });
          }
        })
        .catch((err) => message.error(err.message));
      form.resetFields();
    }
  };

  return (
    <InfoCard title="Set new password" type="default">
      <Form
        form={form}
        className="forgot-password-form"
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item>
          <Text>Please enter your new password (minimum 8 symbols)</Text>
        </Form.Item>
        <Form.Item
          key="password-input"
          name="password"
          rules={[{ required: true, message: 'Please input your password' }]}
        >
          <Input.Password size="large" placeholder="Enter new password" className="large-input" />
        </Form.Item>

        <Form.Item
          key="confirmation-input"
          name="confirmation"
          rules={[{ required: true, message: 'Please input your password confirmation' }]}
        >
          <Input.Password size="large" placeholder="Confirm new password" className="large-input" />
        </Form.Item>

        <Form.Item key="forgot-password-form-btn">
          <Button className="forgot-password-form-btn" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </InfoCard>
  );
};

export default ResetPassword;
