import React, { useState, useEffect, useRef } from 'react';
import dateFormat from 'dateformat';
import {
  Badge,
  Table,
  Card,
  Tooltip,
  Button,
  Modal,
  Typography,
  Space,
  Checkbox,
  Divider,
  Row,
  Col,
  Popover,
  Select,
  Radio,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  ContainerOutlined,
  EyeOutlined,
  MailOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import StateMarker from '../../../../../components/markers/StateMarker/StateMarker';
import DetectionDetails from '../DetectionDetails/DetectionDetails';
import * as utilities from '../../../../../data/utilities/detect-it-utilities';
import './DetectionTable.css';
import {
  updateDetection,
  getDetections,
  getDetectionTotalCounts,
  notifyNewDetection,
} from '../../../../../data/api/queries';
import { GetSingleDetectionResponse } from '../../../../../../../common/src/api/v2';
import useUserStore from '../../../../../data/session/user';
import Preview from './Preview';

interface DetectionWithKey extends GetSingleDetectionResponse {
  index?: number;
  key: string;
}

interface DetectionsState {
  isLoading: boolean;
  page: number;
  detections: DetectionWithKey[];
}

interface OptionStruct {
  label: string;
  value: string;
}

interface CountedDataState {
  hasSelected: boolean;
  indeterminate: boolean;
  filteredData: DetectionWithKey[];
  totalCount: number;
  checkAll: boolean;
  checkedList: string[];
  checkedData: DetectionWithKey[];
  selectedRows: DetectionWithKey[];
  selectedRowKeys: React.Key[];
  labeledOptions: OptionStruct[];
  archivedVisibility: 'archived' | 'active' | 'all';
}

const Detections: React.FC = () => {
  const [token] = useUserStore((store) => [store.token]);

  const [state, setState] = useState<DetectionsState>({
    isLoading: true,
    page: 1,
    detections: [],
  });

  const [countedDataState, setCountedDataState] = useState<CountedDataState>({
    hasSelected: false,
    indeterminate: true,
    filteredData: [],
    totalCount: 0,
    checkAll: false,
    checkedList: ['unclassified'],
    checkedData: [],
    labeledOptions: [],
    selectedRows: [],
    selectedRowKeys: [],
    archivedVisibility: 'active',
  });

  // Destructuring components
  const { Title, Text } = Typography;
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;
  const { confirm } = Modal;

  // Configuring checklist
  const options = [
    { label: 'Unclassified', value: 'unclassified' },
    { label: 'Under Investigation', value: 'under-investigation' },
    { label: 'starling-event', value: 'starling-event' },
  ];

  // Update detections
  const updateData = async (
    isArchived = countedDataState.archivedVisibility,
    checked = countedDataState.checkedList,
    page = state.page
  ) => {
    if (!(isArchived === 'active' || isArchived === 'archived' || isArchived === 'all')) {
      return;
    }
    if (!token) {
      return;
    }
    setState({
      ...state,
      isLoading: true,
    });

    // 'enumifying' the list of checked classes
    const checkedEnum: ('unclassified' | 'starling-event' | 'under-investigation')[] = [];
    checked.forEach((el) => {
      if (el === 'unclassified' || el === 'starling-event' || el === 'under-investigation') {
        checkedEnum.push(el);
      }
    });

    let data;
    if (checkedEnum.length > 0) {
      // no point to fetch data if no classification is selected
      try {
        if (isArchived === 'active' || isArchived === 'archived') {
          const isArchivedState = isArchived === 'active' ? false : true;
          data = await getDetections(
            {
              isArchived: isArchivedState,
              classification: checkedEnum,
              page,
              pageSize: 50,
            },
            token
          );
        } else {
          data = await getDetections(
            {
              classification: checkedEnum,
              page,
              pageSize: 50,
            },
            token
          );
        }
      } catch (e) {
        // message.error('Failed to fetch detections data');
      }
    }

    let counts;
    try {
      let isArchivedParam;
      switch (isArchived) {
        case 'active':
          isArchivedParam = false;
          break;
        case 'archived':
          isArchivedParam = true;
          break;
        default:
        case 'all':
          isArchivedParam = undefined;
      }
      counts = await getDetectionTotalCounts({ isArchived: isArchivedParam }, token);
    } catch (e) {
      message.error('Failed to fetch detections count');
    }

    let detections: DetectionWithKey[] = data
      ? data.results.map((data, index) => ({
          ...data,
          index,
          key: data.id,
        }))
      : [];
    console.log(detections);
    setState({
      ...state,
      isLoading: false,
      detections,
      page,
    });

    // labels for checkboxes
    let labeledOptions;
    if (counts) {
      labeledOptions = [
        {
          label: 'Unclassified (' + counts['unclassified'] + ')',
          value: 'unclassified',
        },
        {
          label: 'Under Investigation (' + counts['underInvestigation'] + ')',
          value: 'under-investigation',
        },
        {
          label: 'Starling Event (' + counts['starlingEvents'] + ')',
          value: 'starling-event',
        },
      ];
    } else {
      labeledOptions = [
        {
          label: 'Unclassified (0)',
          value: 'unclassified',
        },
        {
          label: 'Under Investigation (0)',
          value: 'under-investigation',
        },
        {
          label: 'Starling Event (0)',
          value: 'starling-event',
        },
      ];
    }

    setCountedDataState({
      ...countedDataState,
      archivedVisibility: isArchived,
      labeledOptions,
      totalCount: data ? data.totalCount : 0,
      filteredData: data
        ? data.results.map((data, index) => ({
            ...data,
            index,
            key: data.id,
          }))
        : [],
      checkedList: checkedEnum,
      checkAll: checkedEnum.length === options.length,
      indeterminate: !!checkedEnum.length && checkedEnum.length < options.length,
    });
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    updateData(undefined, undefined, undefined);
  }, [state.page, token]);

  // Managing data presentation based on checkbox selection
  const onCheckChange = (list: any[]) => {
    // const filteredData = utilities.filterData(state.detections, list);
    setCountedDataState({
      ...countedDataState,
      checkedList: list,
      indeterminate: !!list.length && list.length < options.length,
      checkAll: list.length === options.length,
      // filteredData,
    });
    updateData(undefined, list, undefined);
  };

  const onCheckAllChange = (e: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    const ifCheckedAll = e.target.checked ? true : false;
    const list = e.target.checked ? options.map((option) => option.value) : [];
    const filteredData = utilities.filterData(state.detections, list);
    setCountedDataState({
      ...countedDataState,
      checkedList: e.target.checked ? options.map((option) => option.value) : [],
      indeterminate: false,
      checkAll: ifCheckedAll,
      filteredData,
    });
    updateData(undefined, list, undefined);
  };

  // In-row reclassification function
  const handleClassify = (value: string) => {
    const valueArr = value.split('_');
    const id = valueArr[0];
    const classification = valueArr[1];
    async function classifyDetection() {
      if (!token) {
        return;
      }
      if (
        !(classification === 'unclassified') &&
        !(classification === 'starling-event') &&
        !(classification === 'under-investigation')
      ) {
        message.error('Invalid classification');
        return;
      }

      try {
        await updateDetection(
          id,
          {
            classification,
          },
          token
        );
      } catch (e) {
        message.error('Cannot update detections');
      }
    }

    async function makeUpdates() {
      await classifyDetection();
    }

    makeUpdates()
      .then(() => {
        updateData(undefined, undefined, undefined);
      })
      .catch((err) => message.error(err));
  };

  // Managing selected events

  const classificationSelection = (record: DetectionWithKey) => {
    return (
      <Select
        value={utilities.formatEventType(record.classification)}
        style={{ width: 150 }}
        onChange={handleClassify}
      >
        <Option value={record.id + '_unclassified'}>Unclassified</Option>
        <Option value={record.id + '_starling-event'}>Starling Event</Option>
        <Option value={record.id + '_under-investigation'}>Under Investigation</Option>
      </Select>
    );
  };

  // Managing feedback or archive call

  function showArchiveConfirm(selectedRows: DetectionWithKey[]) {
    confirm({
      title: 'Are you sure you want to archive the selected detections?',
      icon: <ExclamationCircleOutlined />,
      content: 'You may unarchive them later',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        async function updateDetections(records) {
          if (!token) {
            return;
          }
          for (const record of records) {
            const data = await updateDetection(
              record.id,
              {
                isArchived: true,
              },
              token
            );
            console.log(data);
          }
        }

        async function makeUpdates() {
          await updateDetections(selectedRows);
        }

        makeUpdates()
          .then(() => {
            updateData(undefined, undefined, undefined);
          })
          .catch((err) => message.error(err));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function showArchiveConfirmSingle(record: DetectionWithKey) {
    const titleAction = record.isArchived ? 'unarchive' : 'archive';
    const contentAction = record.isArchived ? 'archive' : 'unarchive';
    confirm({
      title:
        'Are you sure you want to ' + titleAction + ' detection ' + record.id.split('-')[0] + '?',
      icon: <ExclamationCircleOutlined />,
      content: 'You may ' + contentAction + ' it later',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        async function setArchiveDetection() {
          if (!token) {
            return;
          }

          const data = await updateDetection(
            record.id,
            {
              isArchived: !record.isArchived,
            },
            token
          );
        }

        async function makeUpdates() {
          await setArchiveDetection();
        }

        makeUpdates()
          .then(() => {
            updateData(undefined, undefined, undefined);
          })
          .catch((err) => message.error(err));
      },
      onCancel() {},
    });
  }

  function showEmailConfirm(record: DetectionWithKey) {
    confirm({
      title:
        'Are you sure you want to notify subscribed users about detection ' +
        record.id.split('-')[0] +
        '?',
      icon: <ExclamationCircleOutlined />,
      // content: '',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        async function sendEmailNotification() {
          if (!token) {
            return;
          }
          const data = await notifyNewDetection(record.id, token);
          console.log(data);
        }

        sendEmailNotification()
          .then(() => {
            updateData(undefined, undefined, undefined);
          })
          .catch((err) => message.error('Failed to send notification'));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  // Detection details component
  const expandedRowRender = (record: DetectionWithKey) => {
    return <DetectionDetails record={record} updateData={updateData} />;
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DetectionWithKey[]) => {
      setCountedDataState({
        ...countedDataState,
        hasSelected: selectedRows.length > 0,
        selectedRows,
      });
    },
    getCheckboxProps: (record: DetectionWithKey) => {
      return {
        name: record.id,
      };
    },
  };

  // Managing archived events visibility

  const onArchivedVisibilityChange = (e) => {
    const isArchived = e.target.value;
    setCountedDataState({
      ...countedDataState,
      archivedVisibility: isArchived,
    });
    updateData(isArchived, undefined, undefined);
  };

  const handlePagination = async (page: number) => {
    setState({
      ...state,
      page,
    });
    updateData(undefined, undefined, page);
  };

  const annotationText =
    countedDataState.archivedVisibility === 'all'
      ? 'Showing active and archived events'
      : 'Showing only ' + countedDataState.archivedVisibility + ' events';

  const archivedVisibilityRadioGroup = () => {
    return (
      <>
        <Space direction="vertical">
          <Text strong>Manage visibility of archived events</Text>
          <Radio.Group
            onChange={onArchivedVisibilityChange}
            value={countedDataState.archivedVisibility}
          >
            <Space direction="vertical">
              <Radio value="all">All events</Radio>
              <Radio value="active">Only active events</Radio>
              <Radio value="archived">Only archived events</Radio>
            </Space>
          </Radio.Group>
        </Space>
      </>
    );
  };

  const columns: ColumnsType<DetectionWithKey> = [
    {
      title: 'Event ID',
      key: 'id',
      align: 'center',
      render: (record: DetectionWithKey) => {
        return record.isArchived ? (
          <Text type="secondary">{record.id.split('-')[0]}</Text>
        ) : (
          <Text>{record.id.split('-')[0]}</Text>
        );
      },
    },
    {
      title: 'Device ID',
      key: 'device-id',
      align: 'center',
      render: (record: DetectionWithKey) => <Text>{record.deviceId || 'N/A'}</Text>,
    },
    {
      title: 'Confidence',
      key: 'confidence',
      align: 'center',
      render: (record: DetectionWithKey) => {
        let confidence = '~';
        if (record.confidence) {
          confidence =
            record.confidence < 100
              ? record.confidence.toFixed(1)
              : (record.confidence / 10).toFixed(1);
        }
        return record.isArchived ? (
          <Text type="secondary">{confidence + '%'}</Text>
        ) : (
          <Text>{confidence + '%'}</Text>
        );
      },
    },
    {
      title: 'Timestamp',
      key: 'createdAt',
      align: 'center',
      render: (record: DetectionWithKey) => {
        return record.isArchived ? (
          <Text type="secondary">
            {dateFormat(record.createdAt, 'dddd, mmmm dS, yyyy, h:MM TT')}
          </Text>
        ) : (
          <Text>{dateFormat(record.createdAt, 'dddd, mmmm dS, yyyy, h:MM TT')}</Text>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      width: 200,
      sorter: (a, b) => {
        return a.classification.localeCompare(b.classification);
      },
      render: (record: DetectionWithKey) => {
        const type = utilities.getMarkerTypeByStatus(
          utilities.formatEventType(record.classification)
        );
        return (
          <Space>
            <StateMarker type={type} />
            {classificationSelection(record)}
          </Space>
        );
      },
    },
    {
      title: 'Preview',
      key: 'url',
      align: 'center',
      render: (record: Record<string, string>) => {
        // const url = config.s3WavBucketUrl + record.id + '/' + record.previewImageUrl;
        // const smallImage = (
        //   <img
        //     ref={(el) => (imgRefs.current[record.index] = el)}
        //     className="spect-preview"
        //     alt=""
        //     src={url}
        //   />
        // );
        // const largeImage = <img className="spect-preview-large" alt="" src={url} />;

        return (
          // <Popover placement="left" content={largeImage}>
          <Preview record={record}></Preview>
          // {/* {imgRefs.current[record.index] && imgRefs.current[record.index]?.height
          //   ? smallImage
          //   : 'No preview'} */}
          // </Popover>
        );
      },
    },
    {
      title: 'Actions',
      key: 'details',
      align: 'center',
      render: (record) => {
        const titleAction = record.isArchived ? 'Unarchive' : 'Archive';
        return (
          <Space>
            <Tooltip placement="top" title={titleAction}>
              <Button
                key="2"
                icon={<ContainerOutlined />}
                onClick={() => {
                  showArchiveConfirmSingle(record);
                }}
              />
            </Tooltip>
            {record.lastEmailSentAt ? (
              <Tooltip placement="top" title="Email notification sent">
                <Badge dot status="success">
                  <Button
                    key="3"
                    icon={<MailOutlined />}
                    onClick={() => {
                      return showEmailConfirm(record);
                    }}
                  />
                </Badge>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Click to send mail notification">
                <Button
                  key="3"
                  icon={<MailOutlined />}
                  onClick={() => {
                    return showEmailConfirm(record);
                  }}
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <Row>
          <Col span={8}>
            <Title level={5}>Starling Detections</Title>
          </Col>
          <Col span={16}>
            <Row justify="end">
              <Tooltip key="renew-btn-tooltip" placement="top" title="Update data">
                <Button
                  type="primary"
                  className="arch-switch"
                  icon={<RedoOutlined />}
                  onClick={() => {
                    updateData();
                  }}
                />
              </Tooltip>
              <Popover
                key="archive-btn-popover"
                placement="left"
                content={archivedVisibilityRadioGroup}
              >
                <Button className="arch-switch" icon={<EyeOutlined />} />
              </Popover>
              <Tooltip key="archive-btn-tooltip" placement="top" title="Archive Selected Events">
                <Button
                  icon={<ContainerOutlined />}
                  disabled={!countedDataState.hasSelected}
                  onClick={() => {
                    return showArchiveConfirm(countedDataState.selectedRows);
                  }}
                />
              </Tooltip>
              <br />
              <br />
            </Row>
          </Col>
        </Row>
        <Checkbox
          indeterminate={countedDataState.indeterminate}
          onChange={onCheckAllChange}
          checked={countedDataState.checkAll}
        >
          Show all
        </Checkbox>
        <Divider type="vertical" />
        <CheckboxGroup
          className="detection-checkboxes"
          options={countedDataState.labeledOptions}
          value={countedDataState.checkedList}
          onChange={onCheckChange}
        />
        <div className="archived-annotation">
          <Text italic>{annotationText} </Text>
          <Tooltip title="Change visibility of archived events by selecting an option in the menu available in the top right corner (under the 'eye' icon)">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        <Table
          rowSelection={{
            ...rowSelection,
          }}
          pagination={{
            showSizeChanger: false,
            pageSize: 50,
            current: state.page,
            total: countedDataState.totalCount,
            // total: state.detections.length,
            onChange: (page) => {
              return handlePagination(page);
            },
          }}
          scroll={{ x: '1000' }}
          dataSource={countedDataState.filteredData}
          columns={columns}
          expandable={{ expandedRowRender }}
          size="small"
          loading={state.isLoading}
        />
      </Card>
    </>
  );
};

export default Detections;
