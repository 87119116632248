import { useEffect } from 'react';
import { Form, Select, Button, Checkbox, Input, message } from 'antd';
import PropTypes from 'prop-types';
import { SafeUserV2 } from '../../../../../../../common/src/api/v2';
import { updateUser, setUserRole } from '../../../../../data/api/queries';
import useUserStore from '../../../../../data/session/user';
import './UpdateUserForm.css';

interface UserWithKey extends SafeUserV2 {
  key: string;
}

interface UserProps {
  currentUser: UserWithKey;
  updateData: () => Promise<void>;
  handleOk: () => void;
}

const UpdateRoleForm: React.FC<UserProps> = ({ currentUser, updateData, handleOk }) => {
  const [token] = useUserStore((store) => [store.token]);
  const [form] = Form.useForm();
  const { Option } = Select;

  const label =
    currentUser.isSubscribed === true ? 'Remove from mailing list' : 'Add to mailing list';

  const onFinish = async (values: Record<string, unknown>) => {
    async function handleUserUpdate() {
      if (!token) {
        return;
      }
      if (!(values.role === 'admin') && !(values.role === 'user')) {
        message.error('Invalid role entry');
        return;
      }
      if (values.role !== currentUser.role) {
        try {
          const data = await setUserRole(
            currentUser.id,
            {
              role: values.role,
            },
            token
          );
          updateData();
        } catch (e) {
          message.error('Cannot update role');
        }
      }
      if (values.subscription || values.name !== currentUser.fullName) {
        console.log('Updating subscription and/or name');
        if (!(typeof values.name === 'string')) {
          return;
        }
        if (!(typeof values.subscription === 'boolean' || values.subscription === undefined)) {
          return;
        }
        console.log({
          ...(values.name !== currentUser.fullName && { fullName: values.name }),
          ...(values.subscription && { isSubscribed: !currentUser.isSubscribed }),
        });
        try {
          const data = await updateUser(
            currentUser.id,
            {
              ...(values.name !== currentUser.fullName && { fullName: values.name }),
              ...(values.subscription && { isSubscribed: !currentUser.isSubscribed }),
            },
            token
          );
          console.log(data);
          updateData();
        } catch (e) {
          message.error('Cannot update subscription status');
        }
      }
    }

    if (
      values.role === currentUser.role &&
      !values.subscription &&
      values.name === currentUser.fullName
    ) {
      message.info('No user settings have been changed');
    } else {
      handleUserUpdate()
        .then(() => {
          handleOk();
          updateData();
        })
        .catch((err) => message.error(err));
    }
    form.resetFields();
  };

  // Ensuring that initial values in the form are updated with new entries
  const defaultValues = {
    role: currentUser.role,
    name: currentUser.fullName,
  };
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  return (
    <Form
      form={form}
      key={currentUser.id}
      className="login-form"
      layout="vertical"
      name={currentUser.id + '-update-role-form'}
      initialValues={defaultValues}
      onFinish={onFinish}
    >
      <Form.Item key="name-input" label="Name" name="name">
        <Input />
      </Form.Item>

      <Form.Item key="role-input" name="role" label={'Select new role for ' + currentUser.email}>
        <Select value={currentUser.role}>
          <Option value="user">User</Option>
          <Option value="admin">Admin</Option>
        </Select>
      </Form.Item>

      <Form.Item key="subscription-input" name="subscription" valuePropName="checked">
        <Checkbox>{label}</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          key="user-settings-form-btn"
          className="user-settings-form-btn"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

UpdateRoleForm.propTypes = {
  currentUser: PropTypes.any.isRequired,
  updateData: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
};

export default UpdateRoleForm;
