import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Modal, Typography, Space, Row, Col, message } from 'antd';
import {
  UserAddOutlined,
  DeleteOutlined,
  ControlOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import UpdateUserForm from '../UpdateUserForm/UpdateUserForm';
import AddUserForm from '../AddUserForm/AddUserForm';
import { getUsers, deleteUser } from '../../../../../data/api/queries';
import { SafeUserV2 } from '../../../../../../../common/src/api/v2';
import useUserStore from '../../../../../data/session/user';

interface UserWithKey extends SafeUserV2 {
  key: string;
}

interface UsersState {
  isLoading: boolean;
  page: number;
  users: UserWithKey[];
  currentUser: UserWithKey;
}

const UserManagement: React.FC = () => {
  const [token, role] = useUserStore((store) => [store.token, store.role]);
  const { Title, Text } = Typography;
  const { confirm } = Modal;

  const [state, setState] = useState<UsersState>({
    isLoading: true,
    page: 1,
    users: [],
    currentUser: {
      key: 'undefined',
      fullName: 'undefined',
      id: 'undefined',
      role: 'user',
      email: 'undefined',
      isSubscribed: false,
      createdAt: new Date(),
      modifiedAt: new Date(),
    },
  });

  const updateUsers = async () => {
    if (!token) {
      return;
    }
    setState({
      ...state,
      isLoading: true,
    });

    let data;
    try {
      data = await getUsers(
        {
          page: state.page,
          pageSize: 10,
        },
        token
      );
    } catch (e) {
      message.error('Failed to fetch user data');
    }

    setState({
      ...state,
      isLoading: false,
      users: data.results.map((data) => ({
        ...data,
        key: data.id,
      })),
    });
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    updateUsers();
  }, [state.page, token]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewUserModalVisible, setIsNewUserModalVisible] = useState(false);

  const handleNewUserModalOk = () => {
    setIsNewUserModalVisible(false);
  };

  const handleNewUserModalCancel = () => {
    setIsNewUserModalVisible(false);
  };

  const showNewUserModal = () => {
    setIsNewUserModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (record: UserWithKey) => {
    setState({
      ...state,
      currentUser: record,
    });
    setIsModalVisible(true);
  };

  function showDeleteConfirm(record: UserWithKey) {
    confirm({
      title: 'Are you sure you want to remove ' + record.email + ' account?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action is irreversible',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        if (!(role === 'admin')) {
          return;
        }
        async function handleDeleteUser() {
          if (!token) {
            return;
          }
          await deleteUser(record.id, token);
        }

        handleDeleteUser()
          .then(() => {
            updateUsers();
          })
          .catch((err) => message.error(err.message));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns: ColumnsType<UserWithKey> = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      align: 'center',
      render: (role: string) => {
        let roleName = '';
        if (role === 'admin') {
          roleName = 'Admin';
        } else {
          roleName = 'User';
        }
        return <Text>{roleName}</Text>;
      },
    },

    {
      title: 'Mailing list status',
      dataIndex: 'isSubscribed',
      key: 'subscription',
      align: 'center',
      render: (subscription: boolean) => {
        let mailingStatus = '';
        if (subscription === false) {
          mailingStatus = 'Not subscribed';
        } else {
          mailingStatus = 'Subscribed';
        }
        return <Text>{mailingStatus}</Text>;
      },
    },
    role === 'admin'
      ? {
          title: 'Actions',
          key: 'details',
          align: 'center',
          render: (record: UserWithKey) => {
            return (
              <Space>
                <Button
                  key={record.email + '-chart'}
                  icon={<ControlOutlined />}
                  onClick={() => {
                    return showModal(record);
                  }}
                />
                <Button
                  key={record.email + '-delete'}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    return showDeleteConfirm(record);
                  }}
                />
              </Space>
            );
          },
        }
      : {},
  ];

  return (
    <Card>
      <Row>
        <Col span={8}>
          <Title level={5}>Users</Title>
        </Col>
        {role === 'admin' && (
          <Col span={16}>
            <Row justify="end">
              <Button
                type="primary"
                onClick={() => {
                  return showNewUserModal();
                }}
                icon={<UserAddOutlined />}
              >
                Add User
              </Button>
              <br />
              <br />
            </Row>
          </Col>
        )}
      </Row>
      <Table
        pagination={{
          hideOnSinglePage: true,
        }}
        scroll={{ x: '1000' }}
        dataSource={state.users}
        columns={columns}
        size="small"
      />
      <Modal
        style={{ top: 50 }}
        width={400}
        title="Update user settings"
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <UpdateUserForm
          currentUser={state.currentUser}
          updateData={updateUsers}
          handleOk={handleOk}
        />
      </Modal>
      <Modal
        style={{ top: 50 }}
        width={400}
        title="Create new user"
        visible={isNewUserModalVisible}
        footer={null}
        onOk={handleNewUserModalOk}
        onCancel={handleNewUserModalCancel}
      >
        <AddUserForm updateData={updateUsers} handleOk={handleNewUserModalOk} />
      </Modal>
    </Card>
  );
};

export default UserManagement;
