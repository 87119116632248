import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import Admin from './views/admin/layout/AdminLayout/AdminLayout';
import Base from './views/base/layout/BaseLayout/BaseLayout';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/" component={Base} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
