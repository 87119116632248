import Detections from './admin/pages/Detections/DetectionTable/DetectionTable';
import SingleDetection from './admin/pages/Detections/SingleDetection/SingleDetection';
import UserManagement from './admin/pages/UserManagement/UserTable/UserTable';
import Login from './base/pages/Login/Login';
import Error from './base/pages/Error/Error';
import ForgotPassword from './base/pages/ForgotPassword/ForgotPassword';
import ResetPassword from './base/pages/ResetPassword/ResetPassword';

import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

const routes = [
  {
    path: '/detections/:detectionId',
    name: 'Detection',
    icon: UploadOutlined,
    component: SingleDetection,
    layout: '/admin',
  },
  {
    path: '/detections',
    name: 'Detections',
    icon: UploadOutlined,
    component: Detections,
    layout: '/admin',
    exact: true,
  },
  {
    path: '/usermanagement',
    name: 'UserManagement',
    icon: VideoCameraOutlined,
    component: UserManagement,
    layout: '/admin',
  },
  {
    path: '/login',
    name: 'Login',
    icon: UserOutlined,
    component: Login,
    layout: '',
  },
  {
    path: '/error',
    name: 'Error',
    icon: UserOutlined,
    component: Error,
    layout: '',
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    icon: UserOutlined,
    component: ForgotPassword,
    layout: '',
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    icon: UserOutlined,
    component: ResetPassword,
    layout: '',
  },
];

export default routes;
