import { Form, Select, Button, Input, message } from 'antd';
import { register } from '../../../../../data/api/queries';
import useUserStore from '../../../../../data/session/user';
import './AddUserForm.css';

interface UserProps {
  updateData: () => Promise<void>;
  handleOk: () => void;
}

const AddUserForm: React.FC<UserProps> = ({ updateData, handleOk }) => {
  const [token] = useUserStore((store) => [store.token]);
  const [form] = Form.useForm();
  const { Option } = Select;

  const onFinish = (values: Record<string, string>) => {
    async function createUser() {
      if (!token) {
        return;
      }
      if (!(values.role === 'admin' || values.role === 'user')) {
        return;
      }
      try {
        const data = await register(values.name, values.email, values.role, token);
        console.log(data);
        updateData();
      } catch (e) {
        message.error('Cannot create user');
      }
    }

    createUser()
      .then(() => {
        handleOk();
      })
      .catch((err) => message.error(err.message));
    handleOk();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      key="add-user-form"
      className="add-user-form"
      layout="vertical"
      name="add-user-form"
      onFinish={onFinish}
    >
      <Form.Item
        key="name-input"
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input the user's username" }]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        key="email-input"
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input the user's email" }]}
      >
        <Input placeholder="Enter email" />
      </Form.Item>

      <Form.Item
        key="role-input"
        name="role"
        label="Role"
        rules={[{ required: true, message: "Please select the user's role" }]}
      >
        <Select placeholder="Select role">
          <Option value="user">User</Option>
          <Option value="admin">Admin</Option>
        </Select>
      </Form.Item>

      <Form.Item key="add-user-btn">
        <Button className="login-form-btn" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddUserForm;
