import './StateMarker.css';
import PropTypes from 'prop-types';

const StateMarker: React.FC<{ type: string }> = ({type}) => {
  return (
    <div className={'state-marker ' + type}></div>
  );
};

StateMarker.propTypes = {
  type: PropTypes.oneOf(['warning', 'default', 'success', 'danger', 'info', 'delete', 'muted']).isRequired,
};

export default StateMarker;