import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Typography, message } from 'antd';
import { getDetectionById } from '../../../../../data/api/queries';
import { GetSingleDetectionResponse } from '../../../../../../../common/src/api/v2';
import useUserStore from '../../../../../data/session/user';
import DetectionDetails from '../DetectionDetails/DetectionDetails';

interface DetectionWithKey extends GetSingleDetectionResponse {
  key: string;
}

const SingleDetection: React.FC = () => {
  const [token] = useUserStore((store) => [store.token]);
  const { Title } = Typography;

  const location = useLocation();
  const id = location.pathname.split('/').slice(-1)[0];

  const [detection, setDetection] = useState<DetectionWithKey>({
    key: 'unknown',
    id: 'unknown',
    createdAt: new Date(),
    modifiedAt: new Date(),
    notes: '...',
    latitude: 0,
    longitude: 0,
    classification: 'unclassified',
    isProcessed: false,
    isArchived: false,
    originalFilename: '...',
    previewImageUrl: '...',
    attachments: [],
    confidence: 0,
    timestamp: new Date(),
    type: 'starling',
  });

  const updateData = async () => {
    if (!token) {
      return;
    }
    let updatedDetection: GetSingleDetectionResponse;
    try {
      updatedDetection = await getDetectionById(id, token);
      setDetection({ ...updatedDetection, key: id });
    } catch (e) {
      message.error('Failed to fetch detection data');
    }
  };

  useEffect(() => {
    async function getDetection() {
      if (!token) {
        return;
      }
      try {
        let detection = await getDetectionById(id, token);
        setDetection({ ...detection, key: detection.id });
      } catch (e) {
        message.error('Detection unavailable');
      }
    }

    getDetection();
  }, [id, token]);

  return (
    <Card>
      <Title level={5}>Detection {id.split('-')[0]}</Title>
      <DetectionDetails record={detection} updateData={updateData} />
    </Card>
  );
};

export default SingleDetection;
