import { Link } from 'react-router-dom';
import InfoCard from '../../../../components/cards/InfoCard/InfoCard';
import { Form, Input, Button, Typography, message } from 'antd';
import './ForgotPassword.css';
import { ForgotPasswordRequestSchema } from '../../../../../../common/src/api/v2';
import { forgotPassword } from '../../../../data/api/queries';

export interface ForgotPasswordFormState {
  requestSent: boolean;
}

const ForgotPasswordForm: React.FC = () => {
  // const history = useHistory();
  const { Text } = Typography;

  // const [state, setState] = useState<ForgotPasswordFormState>({
  //   requestSent: false,
  // });

  const onFinish = async (values: Record<string, unknown>) => {
    const forgotPasswordRequest = ForgotPasswordRequestSchema.parse({
      email: values.email,
    });
    try {
      await forgotPassword(forgotPasswordRequest.email);
      // setState({
      //   requestSent: true,
      // });
      message.info('An email has been sent with instructions to reset your password.');
    } catch (e) {
      message.error('Error');
    }
  };

  return (
    <InfoCard title="Set new password" type="default">
      <Form
        className="forgot-password-form"
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item>
          <Text className="large-input">
            Enter your email address, and we will send you a link to reset your password
          </Text>
        </Form.Item>

        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your email address' }]}
        >
          <Input className="large-input" size="large" placeholder="Email" />
        </Form.Item>

        <Form.Item>
          <Link to="/login">
            <Text className="link-text">Return to Login page</Text>
          </Link>
        </Form.Item>

        <Form.Item>
          <Button className="forgot-password-form-btn" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </InfoCard>
  );
};

export default ForgotPasswordForm;
