import {
  detectionClassifications,
  GetSingleDetectionResponse,
} from '../../../../common/src/api/v2';

interface DetectionWithKey extends GetSingleDetectionResponse {
  key: string;
}

export function getMarkerTypeByStatus(status: string): string {
  let type = 'muted';
  let formattedStatus = formatEventType(status);
  if (status === 'Starling Event') {
    type = 'danger';
  } else if (status === 'Under Investigation') {
    type = 'warning';
  }
  return type;
}

export function isClassified(status: string): boolean {
  let isClassified = false;
  if (status === 'Non Event' || status === 'Starling Event' || status === 'Under Investigation') {
    isClassified = true;
  }
  return isClassified;
}

export function filterData(data: DetectionWithKey[], selections: string[]): DetectionWithKey[] {
  data = data.filter((entry) => {
    return selections.includes(entry.classification);
  });
  return data;
}

export function countData(data: DetectionWithKey[], selections: string[]): Record<string, unknown> {
  data = data.filter((entry) => {
    return selections.includes(entry.classification);
  });
  const dataWithMeta = {
    data: data,
  };
  return dataWithMeta;
}

export function countOccurrences(
  inputArray: DetectionWithKey[],
  key: string
): Record<string, string>[] {
  const countedArray: Record<string, string>[] = [];

  inputArray.forEach((entry) => {
    // Checking if there is any object in arr2
    // which contains the key value
    if (
      countedArray.some((val) => {
        return val[key] === entry[key];
      })
    ) {
      // If yes, then increase the occurrence by 1
      countedArray.forEach((countedEntry) => {
        if (countedEntry[key] === entry[key]) {
          const count = Number(countedEntry['occurrence']) + 1;
          countedEntry['occurrence'] = String(count);
        }
      });
    } else {
      // If not then create a new object, initialize
      // it with the present iteration key's value and
      // set the occurrence to 1
      const newOccurrence: Record<string, string> = {};
      newOccurrence[key] = entry[key];
      newOccurrence['occurrence'] = '1';
      countedArray.push(newOccurrence);
    }
  });
  return countedArray;
}

export function getOccurrence(inputArray: Record<string, string>[], value: string): string {
  let a = '0';
  // extract relevant count from counted occurences
  inputArray.forEach((entry) => {
    if (entry['classification'] === value) {
      a = entry['occurrence'];
    }
  });
  return a;
}

export function formatEventsType(events: string[]): string[] {
  const formattedEvents = events
    .map((event) => event.replace('-', ' '))
    .map((event) => makeTitleCase(event));
  return formattedEvents;
}

export function unformatEventsType(events: string[]): string[] {
  const formattedEvents = events
    .map((event) => event.replace(' ', '-'))
    .map((event) => event.toLowerCase());
  return formattedEvents;
}

export function formatEventType(event: string): string {
  const formattedEvent = makeTitleCase(event.replace('-', ' '));
  return formattedEvent;
}

export function unformatEventType(event: string): string {
  const formattedEvent = event.replace(' ', '-').toLowerCase();
  return formattedEvent;
}

function makeTitleCase(str: string): string {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
