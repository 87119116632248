import { useEffect, useRef, useState } from "react";
import { Button } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import colormap from 'colormap';
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.js";
import SpectrogramPlugin from "wavesurfer.js/dist/plugin/wavesurfer.spectrogram.js";
import "./Waveform.css"

const colors = colormap({
  colormap: 'cool',
  nshades: 256,
  format: 'float',
  alpha: 1,
})

export default function Waveform({ url, id }) {
  const specContainerId = "wave-spectrogram-" + id;
  const timelineContainerId = "wave-timeline-" + id;
  const formWaveSurferOptions = (ref) => {
    return {
      container: ref,
      waveColor: "#eee",
      progressColor: "#1890FF",
      cursorColor: "#1890FF",
      cursorWidth: 2,
      barWidth: 3,
      barRadius: 3,
      responsive: true,
      height: 20,
      normalize: true,
      disableRender: true,
      plugins: [
        SpectrogramPlugin.create({
          container: "#" + specContainerId,
          labels: true,
          colorMap: colors,
          frequencyMax: 20000,
        }),
        TimelinePlugin.create({
          container: '#' + timelineContainerId,
        }),
        RegionsPlugin.create(),
      ],

    }
  };

  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [isReady, setIsReady] = useState(true)

  // Create new WaveSurfer instance onComponentMount and when URL changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);
    setIsReady(wavesurfer.current.isReady)

    wavesurfer.current.on('ready', function () {
      wavesurfer.current.drawer.container.style.display = '';
      wavesurfer.current.drawBuffer();
      setIsReady(wavesurfer.current.isReady)
    });

    wavesurfer.current.on('finish', function () {
      setPlay(false);
    });

    // Removes events, elements and disconnects Web Audio nodes when component unmount
    return () => { return wavesurfer.current.destroy() };

  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div>
      <div id={specContainerId} />
      {!isReady && "Spectrogram Not Available"}
      <div id={timelineContainerId} />
      <br />
      <div id="waveform" ref={waveformRef} />
      <br />
      <Button
        type='primary'
        onClick={handlePlayPause}
        icon={!playing ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
        disabled={!isReady}
      >
        {!playing ? 'Play' : 'Pause'}
      </Button>
    </div>
  );
}

Waveform.propTypes = {
  url: PropTypes.string.isRequired,
};