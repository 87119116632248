import { useState, useRef, useCallback, useMemo } from 'react';
import { Typography, Button, Progress, Space, message, Tooltip } from 'antd';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import useUserStore from '../../../../../data/session/user';
import {
  deleteDetectionAttachment,
  getDetectionAttachmentPresignedUpload,
  updateDetectionAttachment,
} from '../../../../../data/api/queries';
import axios from 'axios';
import { GetSingleDetectionResponse } from '../../../../../../../common/src/api/v2';
import config from '../../../../../config';
import './Upload.css';

function getAttachmentUrl(detectionId: string, filename: string) {
  return `${config.s3WavBucketUrl}${detectionId}/attachments/${filename}`;
}

interface UploadFilesState {
  files: FileList | null;
  uploadState: 'init' | 'uploading' | 'error' | 'success';
  uploadProgress: number;
}

interface UploadProps {
  updateData: () => Promise<void>;
  detection: GetSingleDetectionResponse;
}

const Upload: React.FC<UploadProps> = ({ updateData, detection }) => {
  const { Title, Text } = Typography;
  const [token] = useUserStore((store) => [store.token]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<UploadFilesState>({
    files: null,
    uploadState: 'init',
    uploadProgress: 0,
  });

  const handleFileSelectClick = () => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((s) => ({
        ...s,
        files: event.target.files,
        uploadState: 'init',
        uploadProgress: 0,
      }));
    },
    [setState]
  );

  const handleUploadClick = useCallback(async () => {
    if (!token) {
      return;
    }

    setState((s) => ({
      ...s,
      uploadState: 'uploading',
      uploadProgress: 0,
    }));

    try {
      const file = state.files && state.files.item(0);
      if (!file) {
        throw new Error('No file selected!');
      }
      // Get a presigned upload URL so we can directly upload to S3
      const { url, fields } = await getDetectionAttachmentPresignedUpload(
        detection.id,
        file.name,
        file.type,
        token
      );

      // Build the direct upload request to S3
      const formData = new FormData();
      const formFields = fields;
      for (const key in formFields) {
        formData.append(key, formFields[key]);
      }
      formData.append('acl', 'public-read');
      if (file.type) {
        formData.append('Content-Type', file.type);
      }
      formData.append('file', file);
      console.log(`Uploading to: ${url}`, fields);
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event) => {
          const loaded = event.loaded;
          const total = event.total;
          const uploadProgress = (loaded * 100) / total;
          setState((s) => ({
            ...s,
            uploadProgress,
          }));
        },
      });
      console.log('Done');

      // Tell our API that we want to attach the uploaded file
      await updateDetectionAttachment(detection.id, file.name, file.type, token);

      setState((s) => ({
        ...s,
        uploadState: 'success',
        uploadProgress: 0,
        files: null,
      }));
    } catch (err) {
      console.error(err);
      message.error((err as Error).message);
      setState((s) => ({
        ...s,
        uploadState: 'error',
      }));
    }

    try {
      await updateData();
    } catch (err) {
      console.error(err);
      message.error((err as Error).message);
    }
  }, [setState, state.files, detection.id, token, updateData]);

  const deleteAttachment = useCallback(
    async (id: string, filename: string) => {
      if (!token) {
        return;
      }

      try {
        await deleteDetectionAttachment(id, filename, token);
        await updateData();
      } catch (err) {
        console.error(err);
        message.error((err as Error).message);
      }
    },
    [token, updateData]
  );

  const { uploadState, uploadProgress } = state;
  const fileSelected = useMemo(() => state.files && state.files.length > 0, [state.files]);
  const selectedFilename = useMemo(() => {
    if (fileSelected && state.files && state.files.item(0)) {
      return state.files.item(0)!.name;
    }
    return '';
  }, [fileSelected, state.files]);

  return (
    <div>
      {uploadState === 'uploading' ? (
        <div>
          <Text italic>
            Uploading {selectedFilename}... {uploadProgress.toFixed(1)}%
          </Text>
          <Progress percent={Math.round(uploadProgress * 100) / 100} status="active" />
        </div>
      ) : (
        <Text italic>Selected file: {fileSelected ? selectedFilename : 'none'}</Text>
      )}
      <div className="break" />
      {uploadState === 'success' && <Title level={5}>Upload succeeded!</Title>}
      <Space direction="vertical">
        <Space>
          <Button onClick={handleFileSelectClick} disabled={uploadState === 'uploading'}>
            <input ref={fileInputRef} type="file" hidden onChange={handleFileChange} />
            Select file
          </Button>
          <Button
            icon={<UploadOutlined />}
            color="primary"
            type="primary"
            onClick={handleUploadClick}
            disabled={!fileSelected || uploadState === 'uploading'}
          >
            Upload
          </Button>
        </Space>
        {/* <div className="break" /> */}
        {/** Sample code to list attachments */}
        {detection.attachments.map((attachment) => (
          <Space>
            <Button
              size="small"
              key={'name' + attachment.filename}
              icon={<PaperClipOutlined />}
              target="_blank"
              type="link"
              rel="noreferrer"
              href={getAttachmentUrl(detection.id, attachment.filename)}
            >
              {attachment.filename}
            </Button>
            <Tooltip key={'tooltip' + attachment.filename} title="Delete attachment">
              <Button
                size="small"
                type="text"
                key={'delete' + attachment.filename}
                danger
                icon={<DeleteOutlined />}
                onClick={() => deleteAttachment(detection.id, attachment.filename)}
              />
            </Tooltip>
          </Space>
        ))}
      </Space>
    </div>
  );
};

export default Upload;
